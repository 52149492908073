
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPlant } from "@/interfaces/models/IPlant";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from "../../../config/Formats";

@Component({
  methods: {
    DEFAULT_DATE_FORMAT() {
      return DEFAULT_DATE_FORMAT;
    },
    DEFAULT_DATE_TIME_FORMAT() {
      return DEFAULT_DATE_TIME_FORMAT;
    },
  },
  components: {},
})
export default class PlantShowInfoTable extends Vue {
  @Prop({ required: true })
  protected plant!: IPlant;
}
